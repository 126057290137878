import React from "react"
import { graphql } from "gatsby"
import { PortableText } from "@portabletext/react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"
import Layout from "../components/layout"
import BlogHeader from "../components/blogHeader"
import ReactPlayer from "react-player/lazy"
import BlogBuilder from '../components/blogBuilder';

const BlogPostTemplate = props => {
  const { data } = props
  const post = data && data.post
  const {blogBuilder, _rawBlogBuilder} = post

  const sanityConfig = {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_PROJECT_DATASET,
    token: process.env.GATSBY_SANITY_TOKEN,
  }

  console.log("data", data)
  console.log("post", post)

  // const myPortableTextComponents = {
  //   types: {
  //     image: ({value}) => <GatsbyImage src={value.image} />
  //   }
  // }

  return (
    <Layout pageSeo={post}>
      {/* <h1>{post.title}</h1>
        <GatsbyImage
          image={post.featuredImage.asset.gatsbyImageData}
          alt={post.featuredImage.asset.altText}
        /> */}
      <BlogHeader data={post} />

      <BlogBuilder blogBuilder={blogBuilder} _rawBlogBuilder={_rawBlogBuilder} />

      {post._rawPortableText && (
        <section className="section bg-[aliceblue]">
        <div className="container" data-scroll-section data-scroll>
          <div className="prose prose-lg mx-auto">
            <PortableText
              value={post._rawPortableText}
              components={{
                types: {
                  image: ({ value }) => (
                    <GatsbyImage
                      image={getGatsbyImageData(
                        value.asset._ref,
                        { maxWidth: 1024 },
                        sanityConfig
                      )}
                      alt={""}
                    />
                  ),
                  vimeo: ({ value }) => (
                    <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                      <ReactPlayer
                        url={value.url}
                        controls={true}
                        width="100%"
                        height="100%"
                      />
                    </div>
                  ),
                },
              }}
              onMissingComponent={false}
            />
          </div>
        </div>
      </section>
      )}
      

      {post.author && (
        <section className="section bg-primary-3">
          <div className="container max-w-screen-sm mx-auto">
            <article
              className="relative block p-8 overflow-hidden bg-cyan-100 border border-gray-100 rounded-lg"
            >
              <span className="absolute inset-x-0 bottom-0 h-2  bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>

              <div className="justify-between flex">
                <div>
                  <p className="text-xs font-medium text-gray-600">
                    Written by
                  </p>

                  <h5 className="text-xl font-bold text-gray-900 mt-1 ">
                    {post.author.name}
                  </h5>
                </div>
                <GatsbyImage
                    image={post.author.image.asset.gatsbyImageData}
                    alt={post.author.image.altText}
                    className="object-cover w-16 h-16 rounded-full shadow-sm"
                  />

                {/* <div className="flex-shrink-0 hidden ml-3 sm:block">
                
                  <GatsbyImage
                    image={post.author.image.asset.gatsbyImageData}
                    alt={post.author.image.altText}
                    className="object-cover w-16 h-16 rounded-full shadow-sm"
                  />
                </div> */}
              </div>

              <div className="mt-4 sm:pr-8">
                <p className="text-sm text-gray-500">{post.author.text}</p>
              </div>

              <dl className="flex mt-6">
                <div className="flex flex-col-reverse">
                  <dt className="text-sm font-medium text-gray-600">
                    Published
                  </dt>
                  <dd className="text-xs text-gray-500">{post.publishedAt}</dd>
                </div>
              </dl>
            </article>
          </div>
        </section>
      )}
    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityBlog(id: { eq: $id }) {
      _type
      _key
      id
      title
      ...BlogBuilder
      publishedAt(formatString: "do MMM, YYYY")
      _createdAt
      slug {
        current
      }
      blogCategory {
        title
        slug {
          current
        }
      }
      excerpt
      _rawPortableText
      featuredImage {
        asset {
          altText
          gatsbyImageData
        }
      }
      author {
        name
        text
        image {
          asset {
            altText
            gatsbyImageData
          }
        }
      }
      metaDesc
      metaTitle
      shareGraphic {
        asset {
          url
        }
      }
    }
  }
`
