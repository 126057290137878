import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player/vimeo"
import { Link } from "gatsby"

const blogBanner = ({ block }) => {
  const { heading, text, image, buttonLink, buttonLabel } = block

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("animate")
    }
  }, [controls, inView])

  return (
    <motion.section
      ref={ref}
      animate={controls}
      variants={headerAnim}
      initial="initial"
      className="bg-[aliceblue]"
    >
      <div className="container relative">
        <div className="relative after:content-[''] after:absolute after:inset-0 after:bg-stone-900 after:opacity-0">
          <div className="absolute inset-0 flex items-center justify-center z-10">
            <div className="container text-neutral-50 px-6 lg:px-16 ">
              <div className="header__content">
                <motion.h1
                  variants={item}
                  className="header__content-title display uppercase"
                >
                  {heading}
                </motion.h1>
                <motion.p
                  variants={item}
                  className="header__content-desc display-lead mb-7"
                >
                  {text}
                </motion.p>
                <Link to={buttonLink.slug.current} className="btn btn-primary">{buttonLabel}</Link>
              </div>
            </div>
          </div>
          <div className="">
            {image && (
              <div className="">
                <GatsbyImage
                  image={image.asset.gatsbyImageData}
                  alt={image.asset.altText}
                  objectFit="cover"
                  className="w-full h-[70vh] object-cover overflow-hidden rounded-lg"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.section>
  )
}

export default blogBanner

const headerAnim = {
  animate: {
    transition: {
      when: "beforeChildren",
      delayChildren: 0.4,
      staggerChildren: 0.25,
    },
  },
}

const item = {
  initial: { y: 20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.38, 0.005, 0.215, 1],
      duration: 0.6,
    },
  },
}
const postItAnim = {
  initial: {
    y: 20,
    opacity: 0,
    rotate: 0,
    // x: "-50%",
  },
  animate: {
    y: 0,
    // x: "-50%",
    opacity: 1,
    rotate: -6,
    transition: {
      ease: [0.38, 0.005, 0.215, 1],
      duration: 0.6,
    },
  },
}
