import React, { useEffect, useState } from "react"
import { useAnimation, motion, AnimatePresence } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { PortableText } from "@portabletext/react"

const sectionAnim = {
  // initial: {
  //   y: 20,
  // },
  visible: {
    // y: 0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.15,
      ease: [0, 0.55, 0.45, 1],
      duration: 1,
    },
  },
}

const sectionItem = {
  initial: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      // staggerChildren: 0.15,
      ease: [0, 0.55, 0.45, 1],
      duration: 0.8,
    },
  },
}

const blogText = ({ block }) => {
  const { heading, _rawPortableText, image, bgColor, color } = block

  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 0.15,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.section
      className="section bg-[aliceblue]"
      // style={{ backgroundColor: bgColor.value, color: color.value }}
      ref={ref}
      animate={controls}
      initial="initial"
      variants={sectionAnim}
    >
      <div className="container">
        {/* <p>{block.text}</p> */}
        <div className="max-w-screen-lg mx-auto">
          <motion.h1
            variants={sectionItem}
            className="text-3xl lg:text-4xl font-bold mb-4 lg:text-center uppercase"
          >
            {block.heading}
          </motion.h1>

          <motion.div
            variants={sectionItem}
            className="prose prose-lg mx-auto"
            // style={{ color: color.value }}
          >
            <PortableText
              value={_rawPortableText}
              components={{
                types: {
                  image: ({ value }) => (
                    <GatsbyImage
                      image={getGatsbyImageData(
                        value.asset._ref,
                        { maxWidth: 1024 },
                        sanityConfig
                      )}
                      alt={""}
                    />
                  ),
                  vimeo: ({ value }) => (
                    <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                      <ReactPlayer
                        url={value.url}
                        controls={true}
                        width="100%"
                        height="100%"
                      />
                    </div>
                  ),
                },
              }}
              onMissingComponent={false}
            />
          </motion.div>
        </div>
      </div>
    </motion.section>
  )
}

export default blogText
