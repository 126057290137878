import React from 'react'
import blogBanner from '../blogModules/blogBanner';
import blogText from '../blogModules/blogText';
import blogVideo from '../blogModules/blogVideo';
import blogImage from '../blogModules/blogImage';

const BlogBuilder = (props) => {
  const { type, blogBuilder, _rawBlogBuilder } = props;
  const Components = {
    blogBanner: blogBanner,
    blogText: blogText,
    blogVideo: blogVideo,
    blogImage: blogImage
  }
  return blogBuilder.map((block, index) => {
    if (Components[block._type]) {
      return React.createElement(Components[block._type], {
        key: block._key,
        block: block,
        type,
        raw: _rawBlogBuilder[index]
      })
    }
  })
}

export default BlogBuilder
