import React, { useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import ReactPlayer from "react-player/vimeo"

const blogVideo = ({ block }) => {
  const { heading, text, blogVideoItem } = block

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  return (
    <section className="section">
      <div className="container max-w-screen-lg mx-auto">
        <div className="max-w-screen-md text-center mx-auto mb-8">
          <h2 className="display uppercase">{heading}</h2>
          <p className="lead">{text}</p>
        </div>
        
        <div className="navigation-wrapper">
          <div ref={sliderRef} className="keen-slider">
            {blogVideoItem.map((item, index) => {
              return (
                <div className="keen-slider__slide">
                  <div className="aspect-w-16 aspect-h-9 overflow-hidden rounded-lg">
                    <ReactPlayer
                      url={item.vimeoUrl}
                      light={false}
                      // playsinline={true}
                      // muted={true}
                      // controls={false}
                      playing={false}
                      controls={true}
                      // loop={true}
                      width="100%"
                      height="100%"
                      config={{
                        vimeo: {
                          playerOptions: {
                            width: "1080",
                            height: "1920",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          {loaded && instanceRef.current && (
            <>
              <Arrow
                left
                onClick={e =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />

              <Arrow
                onClick={e =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1
                }
              />
            </>
          )}
        </div>
        {loaded && instanceRef.current && (
          <div className="dots">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map(idx => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx)
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                ></button>
              )
            })}
          </div>
        )}
      </div>
    </section>
  )
}

function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  )
}

export default blogVideo
