import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import "./blogHeader.scss"

const BlogHeader = ({ data }) => {
  const { title, featuredImage, excerpt, blogCategory } = data
  console.log("pageHeader", data)

  return (
  
    <section className="blog-header" data-scroll-section data-scroll>
      <div className="blog-header__overlay"></div>
      {featuredImage && (
        <GatsbyImage
          className="blog-header__img w-full"
          image={featuredImage.asset.gatsbyImageData}
          alt={featuredImage.asset.altText}
        />
      )}

      <div className="blog-header__content">
        <div className="container">
          <h1 class="display uppercase max-w-screen-lg mx-auto">{title}</h1>
          <p className="lead max-w-screen-md mx-auto">{excerpt}</p>

          {blogCategory && (
                    <ul className="flex space-x-1 mb-2 justify-center mt-4">
                    {blogCategory.map((item, index) => {
                      return (
                        <li
                          className="inline-block px-3 py-1 text-xs font-semibold text-black bg-primary-3 rounded-full"
                        >
                          <Link to={item.slug.current}>
                            {item.title}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                  )}
        </div>
      </div>
    </section>
  )
}

export default BlogHeader
